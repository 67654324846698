import '@aws-amplify/ui-react/styles.css';
import { signInWithRedirect, getCurrentUser } from 'aws-amplify/auth';
import { useEffect } from 'react';
import {
    useNavigate,
} from "react-router-dom";

const Login = ({ amplifyConfig }) => {
    const navigate = useNavigate()
    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            if (currentUser) {
                console.log(currentUser)
                navigate('/token');
                return;
            }
        } catch (error) {
            signInWithRedirect({ provider: { custom: amplifyConfig.idpProviderName } })
        }
    };
    useEffect(() => {
        getUser();
    }, [])

    return (
        <div>
            <h1>
                Logging you in...
            </h1>
        </div>
    )
}

export default Login