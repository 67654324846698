import React from 'react'
import { signOut } from 'aws-amplify/auth';

const Logout = () => {
    localStorage.removeItem('isAuthenticated')
    signOut();

    return (
        <div>
            <h1>Logging you out .....</h1>
        </div>
    )
}

export default Logout