import React from 'react'
import { fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Image,
  Heading,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
  Link,
  Text
} from '@aws-amplify/ui-react';
const Token = ({ amplifyConfig }) => {
  let navigate = useNavigate()
  const [userInfo, setUserInfo] = useState({})
  async function currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      setUserInfo(idToken)
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    currentSession()
  }, [])
  return (
    <div>
      <br />
      <Flex direction="column"
        justifyContent="center"
        alignItems="center">
        <Image
          alt="Engage Workflow Logo"
          src="/engage-wf-logo.png"
          width="20%"
        />
        <Heading level={3}>
          {amplifyConfig.name} Application
        </Heading>

        <Heading level={5}>
          User Info
        </Heading>
        <Table
          size="small"
          caption=""
          highlightOnHover={true}
          variation="bordered"
          width={800}>
          <TableHead>
            <TableRow>
              <TableCell as="th">Name</TableCell>
              <TableCell as="th">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Client Name</TableCell>
              <TableCell>{amplifyConfig?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Identifier</TableCell>
              <TableCell>{amplifyConfig?.identifier}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{userInfo?.payload?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{userInfo?.payload?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Audience (Cognito client ID)</TableCell>
              <TableCell>{userInfo?.payload?.aud}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issuer</TableCell>
              <TableCell>{userInfo?.payload?.iss}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cognito Groups</TableCell>
              <TableCell>{userInfo?.payload?.['cognito:groups'].join(', ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Token issued at</TableCell>
              <TableCell>
                {new Date(userInfo?.payload?.iat * 1000).toLocaleDateString({ timeZone: 'Asia/Kolkata' })}
                &nbsp;:&nbsp;
                {new Date(userInfo?.payload?.iat * 1000).toLocaleTimeString({ timeZone: 'Asia/Kolkata' })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Token expiry at</TableCell>
              <TableCell>
                {new Date(userInfo?.payload?.exp * 1000).toLocaleDateString({ timeZone: 'Asia/Kolkata' })}
                &nbsp;:&nbsp;
                {new Date(userInfo?.payload?.exp * 1000).toLocaleTimeString({ timeZone: 'Asia/Kolkata' })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Text variation="error">Click the below link to login into Engage Translate</Text>
        <Link
          href={`https://seamless-auth-poc-translate-${amplifyConfig?.identifier}.dev.translate.wl-engage.com/login`}
          color="#007EB9"
          target='_blank'
        >
          {`https://seamless-auth-poc-translate-${amplifyConfig?.identifier}.dev.translate.wl-engage.com/login`}
        </Link>
        <Button variation="primary" onClick={() => { navigate('/logout') }}>Sign out</Button>
      </Flex>
    </div>
  )
}

export default Token