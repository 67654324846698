import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";


import ProtectedRoute from "./Components/ProtectedRoutes";
import Home from "./Components/Home/Home.jsx";
import Login from "./Components/Login/Login.jsx";
import Logout from "./Components/Logout/Logout.jsx";
import Token from "./Components/Token/Token.jsx";
import clientInfo from "./Data/Configs.json";
import { getTenantIdentifier } from "./Utils/TenantHelpers.js";


import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, signOut, getCurrentUser } from 'aws-amplify/auth';


let tenantIdentifier = getTenantIdentifier();
let amplifyConfig = clientInfo.clients.filter((client) => client.identifier === tenantIdentifier)[0]
var authObj = {
  Auth: {
    Cognito: {
      userPoolId: amplifyConfig.userPoolId,
      userPoolClientId: amplifyConfig.clientID,
      loginWith: {
        oauth: {
          domain: amplifyConfig.cognitoDomain,
          scopes: amplifyConfig.scopes,
          redirectSignIn: amplifyConfig.signInRedirectURI,
          redirectSignOut: amplifyConfig.signOutRedirectURI,
          responseType: 'code'
        }
      }
    }
  }
}
Amplify.configure(authObj);

export default function App() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signInWithRedirect':
          getUser();
          localStorage.setItem('isAuthenticated', true)
          break;
        case 'signInWithRedirect_failure':
          setError('An error has ocurred during the Oauth flow.');
          break;
      }
    });

    getUser();

    return unsubscribe;
  }, []);

  const getUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (error) {
    }
  };

  return (
    <div className="App">
      {/* <button onClick={() => signOut()}>Sign Out</button> */}
      <Router>
        <Routes>
          <Route path="/" element={<Home amplifyConfig={amplifyConfig} />} />
          <Route path="login" element={<Login amplifyConfig={amplifyConfig} />} />
          <Route element={<ProtectedRoute />}>
            <Route path="logout" element={<Logout/>} />
            <Route path="token" element={<Token amplifyConfig={amplifyConfig} />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}